#root {
  height: 100%;
  width: 100%;
}

body {
  font-family: 'Barlow', sans-serif;
  background-color: #E6E6E6;
}

.ant-layout-content {
  height: auto;
  width: 100%;
  padding: 84px 24px 24px 24px;
}

.ant-picker-cell-in-view, .ant-picker-month-btn {
  text-transform: capitalize;
}

.narrow .ant-table-tbody>tr>td, .narrow .ant-table-thead>tr>th, .narrow .ant-table tfoot>tr>td, .narrow .ant-table tfoot>tr>th { 
  padding: 12px;
}

@media only screen and (max-width: 576px) {
  .ant-input {
    font-size: 16px;
  }
  
  .ant-layout-content {
    padding: 84px 0 24px 0;
  }
}
