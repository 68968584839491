.ant-message .custom-message .anticon {
  margin-right: 12px;
}

.custom-message .ant-message-custom-content {
  display: flex;
  align-items: center;
}

.custom-content {
  display: flex;
  align-items: center;
}

.custom-content .anticon.anticon-close {
  margin-right: 0px;
  margin-left: 12px;
  color: rgba(0,0,0,0.45);
  cursor: pointer;
}

.custom-content .anticon.anticon-close:hover {
  color: rgba(0,0,0,0.75);
}